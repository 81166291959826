import { Fragment, useState, useEffect, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserCircleIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser, logout } from "../services/apiClient";

// Navigation items with icons
const navigation = [
  {
    name: 'Afschrijven',
    href: '/ordering',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
      </svg>
    )
  },
  {
    name: 'Ophogen',
    href: '/increase',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
      </svg>
    )
  },
  {
    name: 'Geschiedenis',
    href: '/orderhistory',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )
  },
  {
    name: 'Bardienst',
    href: '/barservice',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
      </svg>
    )
  }
];

const Header = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeHover, setActiveHover] = useState(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Reference to detect clicks outside the mobile menu
  const mobileMenuRef = useRef(null);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);

      // If you have a logout function in your API client
      if (typeof logout === 'function') {
        await logout();
      }

      // Clear any local storage or cookies
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');

      // Remove any cookies (if using cookies for authentication)
      document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      // BETTER SOLUTION: Use React Router directly instead of manipulating window.location
      // This properly handles hash routing without causing the URL issues
      navigate('/login', { replace: true });
    } catch (error) {
      console.error("Logout failed:", error);
      // Even if there's an error, redirect to login using React Router
      navigate('/login', { replace: true });
    } finally {
      setIsLoggingOut(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const result = await getCurrentUser();
        if (result.status === 200) {
          setUser(result.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Close mobile menu when location changes
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  // Handle clicks outside of the mobile menu
  useEffect(() => {
    function handleClickOutside(event) {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target) && !event.target.closest('button[aria-label="Toggle mobile menu"]')) {
        setMobileMenuOpen(false);
      }
    }

    // Only add the event listener when the mobile menu is open
    if (mobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [mobileMenuOpen]);

  // Animation styles
  const animationStyles = `
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes slideDown {
      from { transform: translateY(-10px); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }
    
    @keyframes pulse {
      0% { transform: scale(1); }
      50% { transform: scale(1.05); }
      100% { transform: scale(1); }
    }
    
    @keyframes shimmer {
      0% { background-position: -468px 0; }
      100% { background-position: 468px 0; }
    }
    
    .nav-item-hover {
      position: relative;
      overflow: hidden;
    }
    
    .nav-item-hover::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: white;
      transition: width 0.3s ease;
    }
    
    .nav-item-hover:hover::after {
      width: 100%;
    }
    
    .logo-animation {
      transition: transform 0.3s ease;
    }
    
    .logo-animation:hover {
      transform: scale(1.05);
    }
    
    .active-nav-item {
      animation: pulse 0.5s ease;
    }
    
    .shimmer-effect {
      background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 20%, rgba(255,255,255,0) 40%);
      background-size: 1000px 100%;
      animation: shimmer 2s infinite linear;
    }
    
    .mobile-menu-enter {
      animation: slideDown 0.3s forwards ease-out;
    }
    
    .fade-in {
      animation: fadeIn 0.4s ease-in;
    }

    .dropdown-enter {
      animation: slideDown 0.2s forwards ease-out;
    }
    
    .mobile-menu {
      transition: opacity 0.2s ease, transform 0.2s ease;
      opacity: 0;
      transform: translateY(-10px);
      pointer-events: none;
    }
    
    .mobile-menu.open {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
  `;

  if (isLoading) {
    return (
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 h-20 flex items-center justify-center">
        <style>{animationStyles}</style>
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-blue-400 h-10 w-10 shimmer-effect"></div>
          <div className="flex-1 space-y-2 py-1">
            <div className="h-2 bg-blue-400 rounded w-36 shimmer-effect"></div>
            <div className="h-2 bg-blue-400 rounded w-24 shimmer-effect"></div>
          </div>
        </div>
      </div>
    );
  }

  // No user data, possibly not logged in
  if (!user) {
    return (
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 h-20 flex items-center justify-center">
        <style>{animationStyles}</style>
        <div className="text-white fade-in flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
          Sessie verlopen. Opnieuw inloggen.
        </div>
      </div>
    );
  }

  // Filter navigation items based on user permissions
  const filteredNavigation = navigation.filter(item =>
    !(item.name === 'Bardienst' && user.bar_service === 0)
  );

  return (
    <header className="bg-primary relative">
      <style>{animationStyles}</style>
      <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800" style={{ height: '80px' }} />

      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <div className="flex-shrink-0">
            <NavLink to="/" className="logo-animation block">
              <img
                className="h-12 w-auto transition-all duration-300"
                src={require('../images/stamboek_sa.png')}
                alt="Scouting Alblasserdam Stamboek"
              />
            </NavLink>
          </div>

          {/* Desktop navigation */}
          <div className="hidden md:flex md:items-center md:space-x-4 fade-in">
            {/* Navigation links */}
            {filteredNavigation.map((item) => {
              const isActive = location.pathname === item.href;
              const isHovered = activeHover === item.name;

              return (
                <NavLink
                  key={item.name}
                  to={item.href}
                  onMouseEnter={() => setActiveHover(item.name)}
                  onMouseLeave={() => setActiveHover(null)}
                  className={({ isActive }) =>
                    `flex items-center px-4 py-2 text-sm font-medium transition-all duration-200 rounded-md 
                    ${isActive 
                      ? 'bg-white text-blue-600 shadow-md transform hover:scale-105 active-nav-item' 
                      : 'text-blue-100 hover:bg-blue-500 hover:text-white nav-item-hover'}`
                  }
                >
                  <div className={`transition-transform duration-200 ${isHovered && !isActive ? 'scale-110' : ''}`}>
                    {item.icon}
                  </div>
                  {item.name}
                </NavLink>
              );
            })}
          </div>

          {/* User dropdown (desktop) */}
          <div className="hidden md:block">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="flex items-center text-white rounded-full hover:bg-blue-500 hover:bg-opacity-30 px-3 py-2 transition-all duration-200">
                <div className="h-9 w-9 bg-white bg-opacity-20 rounded-full flex items-center justify-center text-white overflow-hidden p-0.5 mr-2">
                  <UserCircleIcon className="h-8 w-8 text-white" aria-hidden="true" />
                </div>
                <div className="text-left mr-1">
                  <p className="text-sm font-medium">{user.name || 'Gebruiker'}</p>
                  <p className="text-xs opacity-80 truncate max-w-[150px]">{user.email || ''}</p>
                </div>
                <ChevronDownIcon className="ml-1 h-5 w-5 text-white" aria-hidden="true" />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 z-50">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <NavLink
                          to="/userdetails"
                          className={`${
                            active ? 'bg-blue-50 text-blue-700' : 'text-gray-700'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                          </svg>
                          Mijn profiel
                        </NavLink>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleLogout}
                          disabled={isLoggingOut}
                          className={`${
                            active ? 'bg-red-50 text-red-700' : 'text-gray-700'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {isLoggingOut ? (
                            <>
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                              Uitloggen...
                            </>
                          ) : (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                              </svg>
                              Uitloggen
                            </>
                          )}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          {/* Mobile menu button */}
          <div className="flex md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2 text-blue-200 hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white transition-all duration-200 hover:scale-105"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              aria-label="Toggle mobile menu"
            >
              <span className="sr-only">{mobileMenuOpen ? 'Close menu' : 'Open menu'}</span>
              {mobileMenuOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu (custom implementation without Popover) */}
      <div
        ref={mobileMenuRef}
        className={`mobile-menu absolute inset-x-0 top-0 z-50 p-3 md:hidden ${mobileMenuOpen ? 'open' : ''}`}
        style={{ marginTop: '80px' }}
      >
        <div className="rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="px-5 pt-4 pb-3 border-b border-gray-200">
            <div className="flex items-center justify-between">
              {/* Logo in mobile menu */}
              <div className="logo-animation">
                <img
                  className="h-8 w-auto"
                  src={require('../images/stamboek_sa.png')}
                  alt="Scouting Alblasserdam"
                />
              </div>

              {/* Close button */}
              <div className="-mr-2">
                <button
                  type="button"
                  className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 transition-all duration-200"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>

            {/* User info in mobile menu */}
            <div className="flex items-center mt-3 bg-blue-50 p-3 rounded-lg">
              <div className="h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 overflow-hidden">
                <UserCircleIcon className="h-9 w-9" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <div className="text-base font-medium text-gray-800">
                  {user.name || 'Gebruiker'}
                </div>
                <div className="text-sm font-medium text-gray-500">
                  {user.email || ''}
                </div>
              </div>
            </div>
          </div>

          {/* Navigation links in mobile menu */}
          <div className="py-2 px-2 space-y-1">
            {filteredNavigation.map((item) => {
              const isActive = location.pathname === item.href;

              return (
                <NavLink
                  key={item.name}
                  to={item.href}
                  onClick={() => setMobileMenuOpen(false)}
                  className={({ isActive }) =>
                    `flex items-center px-3 py-3 text-base font-medium rounded-lg transition-all duration-200
                    ${isActive
                      ? 'bg-blue-100 text-blue-800 shadow-sm transform active-nav-item' 
                      : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'}`
                  }
                >
                  <div className="mr-3 text-blue-600">
                    {item.icon}
                  </div>
                  {item.name}

                  {isActive && (
                    <div className="ml-auto bg-blue-200 text-blue-800 text-xs font-medium px-2 py-0.5 rounded-full">
                      Actief
                    </div>
                  )}
                </NavLink>
              );
            })}

            {/* Logout button in mobile menu */}
            <button
              onClick={() => {
                setMobileMenuOpen(false);
                handleLogout();
              }}
              disabled={isLoggingOut}
              className="flex items-center px-3 py-3 text-base font-medium rounded-lg transition-all duration-200 w-full text-left text-red-700 hover:bg-red-50"
            >
              <div className="mr-3 text-red-600">
                {isLoggingOut ? (
                  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                )}
              </div>
              {isLoggingOut ? 'Uitloggen...' : 'Uitloggen'}
            </button>
          </div>

          <div className="px-5 py-3 bg-gray-50 border-t border-gray-200">
            <div className="text-xs text-gray-500 text-center">
              <span>Stamboek • Scouting Alblasserdam</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;