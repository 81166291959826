import { useState, useEffect } from 'react'
import { getCurrentBalanceCents, increaseBalance } from "../services/apiClient"
import { useParams } from 'react-router-dom';
import { formatEuros, totalCents } from "../utils/finance"
import Alert from './Alert'
import Spinner from './Spinner'
import { PlusCircleIcon, MinusCircleIcon, CreditCardIcon } from '@heroicons/react/24/outline'

const BalanceIncreaser = () => {
    const [increaseBalanceWith, setIncreaseBalanceWith] = useState(0)
    const [currentBalanceCents, setCurrentBalanceCents] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [isIncreasing, setIsIncreasing] = useState(false)
    const [error, setError] = useState(null)

    const params = useParams();
    const newBalance = currentBalanceCents + totalCents([increaseBalanceWith * 100]);
    const suggestedAmounts = [5, 10, 20, 50];

    useEffect(() => {
        const fetchCurrentBalance = async () => {
            try {
                const result = await getCurrentBalanceCents();
                setCurrentBalanceCents(result);
            } catch (err) {
                setError('Er is een fout opgetreden bij het ophalen van je saldo.');
                console.error('Failed to fetch balance:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCurrentBalance();
    }, []);

    const handleIncrease = async () => {
        try {
            setIsIncreasing(true);
            setError(null);

            const amountInCents = increaseBalanceWith * 100;
            const response = await increaseBalance(amountInCents);

            if (response.status === 200) {
                window.location = response.data.url;
            } else {
                throw new Error('Failed to increase balance');
            }
        } catch (err) {
            setError('Helaas, het ophogen van je saldo is mislukt.');
            console.error('Balance increase error:', err);
        } finally {
            setIsIncreasing(false);
        }
    };

    const validateInput = (value) => {
        const numValue = parseFloat(value);
        if (isNaN(numValue) || numValue < 0) {
            setIncreaseBalanceWith(0);
        } else {
            setIncreaseBalanceWith(numValue);
        }
    };

    const selectSuggestedAmount = (amount) => {
        setIncreaseBalanceWith(amount);
    };

    return (
        <div className="max-w-2xl mx-auto px-4">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                {/* Header section */}
                <div className="bg-gradient-to-r from-blue-600 to-blue-800 px-6 py-6 text-white">
                    <h1 className="text-2xl font-bold flex items-center">
                        <CreditCardIcon className="h-7 w-7 mr-2" />
                        Saldo ophogen
                    </h1>
                    <div className="mt-4 flex items-center">
                        <div>
                            <p className="text-sm text-blue-100">Huidig saldo</p>
                            {isLoading ? (
                                <div className="h-8 w-24 animate-pulse bg-blue-400 opacity-50 rounded"></div>
                            ) : (
                                <p className="text-2xl font-bold">
                                    {formatEuros(currentBalanceCents)}
                                </p>
                            )}
                        </div>

                        <div className="ml-auto">
                            <p className="text-sm text-blue-100">Na ophogen</p>
                            <p className="text-2xl font-bold">{formatEuros(newBalance)}</p>
                        </div>
                    </div>
                </div>

                {/* Main content */}
                <div className="p-6">
                    {/* Quick selection buttons */}
                    <div className="mb-6">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Kies een bedrag
                        </label>
                        <div className="grid grid-cols-4 gap-2">
                            {suggestedAmounts.map(amount => (
                                <button
                                    key={amount}
                                    onClick={() => selectSuggestedAmount(amount)}
                                    className={`py-2 px-4 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 
                                    ${increaseBalanceWith === amount 
                                        ? 'bg-blue-600 text-white' 
                                        : 'bg-gray-100 text-gray-800 hover:bg-gray-200'}`}
                                >
                                    {formatEuros(amount * 100)}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Custom amount input */}
                    <div className="mb-6">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Of voer een bedrag in
                        </label>
                        <div className="flex items-center">
                            <button
                                disabled={isLoading || increaseBalanceWith <= 0}
                                onClick={() => validateInput(Math.max(0, increaseBalanceWith - 1))}
                                className="disabled:opacity-50 text-blue-600 hover:text-blue-800 p-2 focus:outline-none"
                                aria-label="Verlaag bedrag"
                            >
                                <MinusCircleIcon className="h-6 w-6" />
                            </button>

                            <div className="relative flex-1 mx-2">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                                    €
                                </span>
                                <input
                                    value={increaseBalanceWith}
                                    onChange={(e) => validateInput(e.target.value)}
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    placeholder="0.00"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 pl-8 py-3 rounded-md w-full focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>

                            <button
                                disabled={isLoading}
                                onClick={() => validateInput(increaseBalanceWith + 1)}
                                className="disabled:opacity-50 text-blue-600 hover:text-blue-800 p-2 focus:outline-none"
                                aria-label="Verhoog bedrag"
                            >
                                <PlusCircleIcon className="h-6 w-6" />
                            </button>
                        </div>
                    </div>

                    {/* Error and success alerts */}
                    {error && (
                        <Alert
                            title='Helaas, het ophogen van je saldo is mislukt.'
                            message='Neem contact met ons op als het probleem zich blijft voordoen. Dank je wel!'
                        />
                    )}

                    {params.status === 'success' && (
                        <Alert title='Ophogen gelukt' message='' color='green' />
                    )}

                    {/* Submit button */}
                    <div className="mt-6">
                        <button
                            onClick={handleIncrease}
                            disabled={isLoading || isIncreasing || increaseBalanceWith <= 0}
                            className="w-full flex justify-center items-center disabled:opacity-50 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-md px-5 py-3 transition-colors"
                        >
                            {isIncreasing ? (
                                <>
                                    <Spinner />
                                    <span className="ml-2">Bezig met ophogen...</span>
                                </>
                            ) : (
                                <>
                                    Saldo ophogen met {formatEuros(increaseBalanceWith * 100)}
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>

            {/* Info section */}
            <div className="mt-6 bg-white rounded-lg shadow-md p-6">
                <h2 className="text-lg font-medium text-gray-800 mb-2">Informatie over ophogen</h2>
                <p className="text-gray-600 text-sm">
                    Je kunt je saldo opwaarderen via iDEAL. Na het klikken op de knop 'Saldo ophogen' word je
                    doorgestuurd naar de beveiligde betaalomgeving. Na een succesvolle betaling wordt je saldo
                    direct bijgewerkt.
                </p>
            </div>
        </div>
    );
};

export default BalanceIncreaser;