import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { getUsers, getCurrentUser } from '../services/apiClient';
import Spinner from './Spinner';
import { MagnifyingGlassIcon, UserCircleIcon, UserPlusIcon } from '@heroicons/react/24/outline';

const NameSelector = () => {
    const [filter, setFilter] = useState('');
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Check if user has bar service permission
                const currentUser = await getCurrentUser();
                if (currentUser.data.data.bar_service === 0) {
                    navigate('/ordering');
                    return;
                }

                // Fetch users list
                const result = await getUsers();
                setUsers(result.data.data);
            } catch (err) {
                console.error("Error loading data:", err);
                setError("Er is een fout opgetreden bij het laden van de gebruikers.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    // Filter users based on search input
    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div className="max-w-3xl mx-auto px-4">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                {/* Header section */}
                <div className="bg-gradient-to-r from-blue-600 to-blue-800 px-6 py-6 text-white">
                    <h1 className="text-2xl font-bold flex items-center">
                        <UserPlusIcon className="h-7 w-7 mr-2" />
                        Bardienst
                    </h1>
                    <p className="mt-2 text-blue-100">
                        Selecteer een gebruiker om voor af te schrijven
                    </p>
                </div>

                {/* Search section */}
                <div className="p-6">
                    <div className="relative mb-6">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                            value={filter}
                            onChange={event => setFilter(event.target.value)}
                            name="filter"
                            type="text"
                            placeholder="Zoeken op naam"
                            className="pl-10 pr-4 py-3 w-full rounded-md bg-gray-50 border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-blue-500 text-sm transition-colors"
                            autoFocus
                        />
                    </div>

                    {/* Error state */}
                    {error && (
                        <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-md">
                            {error}
                        </div>
                    )}

                    {/* Loading state */}
                    {isLoading ? (
                        <div className="py-12 flex justify-center">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            {/* Empty state */}
                            {filteredUsers.length === 0 ? (
                                <div className="py-12 text-center text-gray-500">
                                    <UserCircleIcon className="h-12 w-12 mx-auto text-gray-400" />
                                    <p className="mt-2 text-lg font-medium">Geen gebruikers gevonden</p>
                                    <p className="mt-1">Probeer een andere zoekopdracht</p>
                                </div>
                            ) : (
                                /* Users list */
                                <div className="border rounded-md overflow-hidden">
                                    <ul className="divide-y divide-gray-200">
                                        {filteredUsers.map(user => (
                                            <li key={user.id}>
                                                <NavLink
                                                    to={`/barservice/ordering/${user.id}`}
                                                    state={{ name: user.name }}
                                                    className="block hover:bg-blue-50 transition-colors"
                                                >
                                                    <div className="px-6 py-4 flex items-center">
                                                        <div className="flex-shrink-0">
                                                            <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                                                                <span className="text-blue-600 font-medium">
                                                                    {user.name.charAt(0)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {user.name}
                                                            </div>
                                                            {user.email && (
                                                                <div className="text-sm text-gray-500">
                                                                    {user.email}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="ml-auto">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/* Results count */}
                            <div className="mt-4 text-sm text-gray-500 text-right">
                                {filteredUsers.length} {filteredUsers.length === 1 ? 'gebruiker' : 'gebruikers'} gevonden
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NameSelector;