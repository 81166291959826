import Header from '../components/Header';

const ColumnLayout = ({ children }) => {
    return (
        <>
            <Header />

            <main className="mt-12 pb-8">
                <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                     {children}
                </div>
            </main>
        </>
    )
}

export default ColumnLayout