import { useState, useCallback, useEffect } from 'react';

export const useNegativeBalanceAnimation = () => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [animationTimeout, setAnimationTimeout] = useState(null);

    // Start the negative balance animation
    const startAnimation = useCallback(() => {
        // Clear any existing animation timeout
        if (animationTimeout) {
            clearTimeout(animationTimeout);
        }

        // Set animating state to true
        setIsAnimating(true);

        // Set a timeout to end the animation after 3 seconds
        const timeout = setTimeout(() => {
            setIsAnimating(false);
        }, 3000);

        setAnimationTimeout(timeout);
    }, [animationTimeout]);

    // Check if balance is negative and trigger animation if needed
    const checkNegativeBalance = useCallback((newBalance) => {
        if (newBalance < 0) {
            startAnimation();
            return true;
        }

        return false;
    }, [startAnimation]);

    // Clean up timeouts on unmount
    useEffect(() => {
        return () => {
            if (animationTimeout) {
                clearTimeout(animationTimeout);
            }
        };
    }, [animationTimeout]);

    // Component to be rendered when balance is negative
    const NegativeBalanceAnimationComponent = isAnimating ? (
        <div className="fixed inset-0 pointer-events-none z-50 overflow-hidden">
            {/* Red flash overlay */}
            <div
                className="absolute inset-0 bg-red-600 opacity-0 animate-negative-flash"
            />

            {/* Warning icons that float up from the bottom */}
            <div className="absolute bottom-0 left-0 w-full flex justify-around">
                {[...Array(5)].map((_, i) => (
                    <div key={i} className="animate-float-up" style={{ animationDelay: `${i * 0.2}s` }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            className="h-16 w-16 text-red-500 fill-current"
                            style={{ filter: "drop-shadow(0 0 8px rgba(255, 0, 0, 0.7))" }}
                        >
                            <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
                        </svg>
                    </div>
                ))}
            </div>

            {/* Centered warning message */}
            <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-600 text-white px-8 py-4 rounded-lg shadow-lg animate-pulse-scale">
                <div className="text-center">
                    <h3 className="text-2xl font-bold mb-2">Negatief Saldo!</h3>
                    <p>Je saldo is onder nul gegaan.</p>
                </div>
            </div>
        </div>
    ) : null;

    return {
        isAnimating,
        checkNegativeBalance,
        startAnimation,
        NegativeBalanceAnimationComponent
    };
};

export default useNegativeBalanceAnimation;