import { useState, useCallback, useEffect } from 'react';

export const useDiscoModeEasterEgg = () => {
    const [isDiscoMode, setIsDiscoMode] = useState(false);
    const [discoColors, setDiscoColors] = useState([]);
    const [discoInterval, setDiscoIntervalState] = useState(null);

    // Generate a new set of random vibrant colors
    const generateDiscoColors = useCallback(() => {
        const newColors = [];
        for (let i = 0; i < 5; i++) {
            // Generate vibrant hues for disco effect
            const hue = Math.floor(Math.random() * 360);
            newColors.push(`hsl(${hue}, 100%, 50%)`);
        }
        setDiscoColors(newColors);
    }, []);

    // Start the disco mode
    const startDiscoMode = useCallback(() => {
        if (isDiscoMode) return;

        setIsDiscoMode(true);
        generateDiscoColors();

        // Change colors rapidly for disco effect
        const interval = setInterval(() => {
            generateDiscoColors();
        }, 300);

        setDiscoIntervalState(interval);

        // Stop disco mode after 5 seconds
        setTimeout(() => {
            stopDiscoMode();
        }, 5000);
    }, [isDiscoMode, generateDiscoColors]);

    // Stop the disco mode
    const stopDiscoMode = useCallback(() => {
        if (discoInterval) {
            clearInterval(discoInterval);
            setDiscoIntervalState(null);
        }
        setIsDiscoMode(false);
    }, [discoInterval]);

    // Try to trigger disco mode with 5% chance
    const tryTriggerDiscoMode = useCallback(() => {
        const random = Math.random();
        if (random <= 0.05) { // 5% chance
            startDiscoMode();
            return true;
        }
        return false;
    }, [startDiscoMode]);

    // Clean up on unmount
    useEffect(() => {
        return () => {
            if (discoInterval) {
                clearInterval(discoInterval);
            }
        };
    }, [discoInterval]);

    // Component to be rendered
    const DiscoModeComponent = isDiscoMode ? (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                pointerEvents: 'none',
                zIndex: 1000,
                background: `linear-gradient(45deg, ${discoColors.join(', ')})`,
                opacity: 0.3,
                transition: 'background 0.3s ease'
            }}
        />
    ) : null;

    return {
        isDiscoMode,
        tryTriggerDiscoMode,
        startDiscoMode,
        stopDiscoMode,
        DiscoModeComponent
    };
};