import { useEffect, useState } from "react"
import { getCurrentUser } from "../services/apiClient"
import { formatEuros } from "../utils/finance"
import Spinner from '../components/Spinner';
import { UserCircleIcon, EnvelopeIcon, CurrencyEuroIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';

const UserDetails = () => {
    const [userInfo, setUserInfo] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setIsLoading(true);
                const result = await getCurrentUser();
                if (result.status === 200) {
                    setUserInfo(result.data.data);
                    setIsLoaded(true);
                } else {
                    throw new Error("Failed to load user data");
                }
            } catch (err) {
                console.error("Error fetching user data:", err);
                setError("Er is een fout opgetreden bij het laden van je gegevens.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    // Animation styles
    const animationStyles = `
        @keyframes fadeIn {
            from { opacity: 0; transform: translateY(10px); }
            to { opacity: 1; transform: translateY(0); }
        }
        
        @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
        }
        
        @keyframes slideInRight {
            from { transform: translateX(20px); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
        }
        
        @keyframes shimmer {
            0% { background-position: -468px 0; }
            100% { background-position: 468px 0; }
        }
        
        .fade-in {
            animation: fadeIn 0.6s ease-out forwards;
        }
        
        .slide-in {
            animation: slideInRight 0.5s ease-out forwards;
        }
        
        .profile-card {
            transition: all 0.3s ease;
        }
        
        .profile-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
        }
        
        .shimmer-effect {
            background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
            background-size: 1000px 100%;
            animation: shimmer 2s infinite linear;
        }
        
        .balance-card {
            transition: all 0.3s ease;
        }
        
        .balance-card:hover {
            transform: translateY(-5px);
        }
        
        .detail-item {
            animation-duration: 0.5s;
            animation-fill-mode: both;
            animation-name: fadeIn;
        }
        
        .detail-item:nth-child(1) { animation-delay: 0.1s; }
        .detail-item:nth-child(2) { animation-delay: 0.2s; }
        .detail-item:nth-child(3) { animation-delay: 0.3s; }
        .detail-item:nth-child(4) { animation-delay: 0.4s; }
        
        .bg-gradient-fancy {
            background-image: linear-gradient(135deg, #0061ff 0%, #60efff 100%);
        }
        
        .smooth-shadow {
            box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
        }
    `;

    if (isLoading) {
        return (
            <div className="max-w-4xl mx-auto px-4 py-8">
                <style>{animationStyles}</style>
                <div className="mb-8">
                    <div className="h-8 w-64 bg-gray-200 rounded shimmer-effect mb-8"></div>
                </div>
                <div className="bg-white rounded-2xl p-8 shadow-md">
                    <div className="flex items-center mb-6">
                        <div className="h-16 w-16 rounded-full bg-gray-200 shimmer-effect mr-4"></div>
                        <div>
                            <div className="h-6 w-48 bg-gray-200 rounded shimmer-effect mb-2"></div>
                            <div className="h-4 w-32 bg-gray-200 rounded shimmer-effect"></div>
                        </div>
                    </div>
                    <div className="space-y-6">
                        <div className="h-12 bg-gray-200 rounded shimmer-effect"></div>
                        <div className="h-12 bg-gray-200 rounded shimmer-effect"></div>
                        <div className="h-12 bg-gray-200 rounded shimmer-effect"></div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="max-w-4xl mx-auto px-4 py-8">
                <style>{animationStyles}</style>
                <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-md">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-red-700">{error}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <style>{animationStyles}</style>

            {/* Header */}
            <div className="bg-gradient-fancy text-white rounded-2xl smooth-shadow p-6 mb-8 fade-in">
                <h1 className="text-3xl font-bold flex items-center">
                    <UserCircleIcon className="h-8 w-8 mr-3" />
                    Mijn Profiel
                </h1>
                <p className="mt-2 text-blue-100">
                    Bekijk en beheer je persoonlijke gegevens
                </p>
            </div>

            {isLoaded ? (
                <div className="space-y-8">
                    {/* Profile Card */}
                    <div className="bg-white rounded-2xl shadow-md overflow-hidden profile-card">
                        <div className="p-6 bg-gradient-to-r from-blue-50 to-blue-100 border-b border-blue-200">
                            <h2 className="text-xl font-semibold text-blue-800 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                Persoonlijke Gegevens
                            </h2>
                        </div>

                        <div className="p-6">
                            <div className="flex flex-wrap -mx-4">
                                {/* User avatar and name section */}
                                <div className="px-4 w-full md:w-1/3 mb-6 md:mb-0">
                                    <div className="flex flex-col items-center">
                                        <div className="h-32 w-32 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 mb-4">
                                            <UserCircleIcon className="h-24 w-24" />
                                        </div>
                                        <h3 className="text-xl font-bold text-gray-800">{userInfo.name}</h3>
                                    </div>
                                </div>

                                {/* User details section */}
                                <div className="px-4 w-full md:w-2/3">
                                    <ul className="space-y-6">
                                        <li className="detail-item flex items-center border-b border-gray-100 pb-4">
                                            <div className="mr-4 h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center text-blue-600">
                                                <EnvelopeIcon className="h-5 w-5" />
                                            </div>
                                            <div>
                                                <p className="text-sm text-gray-500">Email</p>
                                                <p className="text-lg font-medium text-gray-800">{userInfo.email}</p>
                                            </div>
                                        </li>

                                        <li className="detail-item flex items-center border-b border-gray-100 pb-4">
                                            <div className="mr-4 h-10 w-10 bg-green-100 rounded-full flex items-center justify-center text-green-600">
                                                <CurrencyEuroIcon className="h-5 w-5" />
                                            </div>
                                            <div>
                                                <p className="text-sm text-gray-500">Huidig Saldo</p>
                                                <p className={`text-lg font-bold ${userInfo.balance < 0 ? 'text-red-600' : 'text-green-600'}`}>
                                                    {formatEuros(userInfo.balance)}
                                                </p>
                                            </div>
                                        </li>

                                        {userInfo.admin === 1 && (
                                            <li className="detail-item flex items-center pb-4">
                                                <div className="mr-4 h-10 w-10 bg-purple-100 rounded-full flex items-center justify-center text-purple-600">
                                                    <ShieldCheckIcon className="h-5 w-5" />
                                                </div>
                                                <div>
                                                    <p className="text-sm text-gray-500">Rechten</p>
                                                    <p className="text-lg font-medium text-purple-700">Administrator</p>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Balance Card */}
                    <div className="bg-white rounded-2xl shadow-md overflow-hidden balance-card slide-in">
                        <div className="p-6 bg-gradient-to-r from-blue-50 to-blue-100 border-b border-blue-200">
                            <h2 className="text-xl font-semibold text-blue-800 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                                </svg>
                                Saldo Overzicht
                            </h2>
                        </div>

                        <div className="p-6">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full md:w-1/2 px-4 mb-6 md:mb-0">
                                    <div className={`rounded-xl p-6 ${userInfo.balance >= 0 ? 'bg-green-50 border border-green-100' : 'bg-red-50 border border-red-100'}`}>
                                        <p className="text-sm text-gray-500 mb-1">Huidig Saldo</p>
                                        <p className={`text-3xl font-bold ${userInfo.balance < 0 ? 'text-red-600' : 'text-green-600'}`}>
                                            {formatEuros(userInfo.balance)}
                                        </p>

                                        <div className="mt-4 pt-4 border-t border-gray-200">
                                            <div className="flex items-center">
                                                <div className={`h-3 w-3 rounded-full mr-2 ${userInfo.balance < 0 ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                <p className="text-sm text-gray-600">
                                                    {userInfo.balance < 0
                                                        ? 'Je hebt een negatief saldo'
                                                        : 'Je saldo is positief'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full md:w-1/2 px-4">
                                    <div className="rounded-xl bg-blue-50 border border-blue-100 p-6 h-full flex flex-col justify-between">
                                        <div>
                                            <p className="text-sm text-gray-500 mb-1">Acties</p>
                                            <p className="text-lg font-medium text-gray-800">Beheer je saldo</p>
                                        </div>

                                        <div className="mt-4 space-y-3">
                                            <a
                                                href="/#/increase"
                                                className="block w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white text-center rounded-lg transition-colors duration-200"
                                            >
                                                Saldo Ophogen
                                            </a>
                                            <a
                                                href="/#/ordering"
                                                className="block w-full py-2 px-4 bg-white border border-blue-200 hover:bg-blue-50 text-blue-600 text-center rounded-lg transition-colors duration-200"
                                            >
                                                Afschrijven
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex justify-center items-center py-16">
                    <Spinner />
                </div>
            )}
        </div>
    );
}

export default UserDetails;