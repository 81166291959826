import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import ColumnLayout from "./ColumnLayout"

const AuthLayout = () => {

    
    const [trigger, setTrigger] = useState(true)

    return (
        <ColumnLayout>
            <Outlet context={[trigger, setTrigger]} />
        </ColumnLayout>
    )
}

export default AuthLayout