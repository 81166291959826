const Footer = () => {
    // Get current year dynamically
    const currentYear = new Date().getFullYear();

    return (
        <footer>
            <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left">
                    <span className="block sm:inline">&copy; {currentYear} Stamboek Scouting Alblasserdam</span>{' '}
                </div>
            </div>
        </footer>
    );
};

export default Footer;