import { Fragment, useEffect, useState } from "react";
import { getOrderHistory } from "../services/apiClient";
import Spinner from "./Spinner";
import { formatEuros } from "../utils/finance";

const HistoryList = () => {
    const [orderHistory, setOrderHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedOrders, setExpandedOrders] = useState({});
    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        const fetchOrderHistory = async () => {
            try {
                setIsLoading(true);
                const result = await getOrderHistory();
                if (result.status === 200) {
                    setOrderHistory(result.data.data);
                } else {
                    throw new Error("Failed to load order history");
                }
            } catch (err) {
                console.error("Error fetching order history:", err);
                setError("Er is een fout opgetreden bij het laden van je afschrijvingen.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrderHistory();
    }, []);

    const toggleOrderExpand = (orderId) => {
        setExpandedOrders(prev => ({
            ...prev,
            [orderId]: !prev[orderId]
        }));
    };

    // Format date in a nice readable way
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('nl-NL', {dateStyle: "short", timeStyle: "short"});
    };

    // Calculate total amount for an order
    const calculateOrderTotal = (orderLines) => {
        return orderLines.reduce((sum, line) => sum + (line.price * line.quantity), 0);
    };

    // Filter orders based on search text
    const filteredOrders = filterText
        ? orderHistory.filter(order =>
            order.done_by_user.toLowerCase().includes(filterText.toLowerCase()) ||
            order.order_lines.some(line => line.name.toLowerCase().includes(filterText.toLowerCase()))
          )
        : orderHistory;

    // Animation styles
    const animationStyles = `
        @keyframes fadeIn {
            from { opacity: 0; transform: translateY(10px); }
            to { opacity: 1; transform: translateY(0); }
        }
        
        @keyframes shimmer {
            0% { background-position: -468px 0; }
            100% { background-position: 468px 0; }
        }
        
        @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
        }
        
        .fade-in {
            animation: fadeIn 0.6s ease-out forwards;
        }
        
        .order-row {
            transition: all 0.2s ease;
        }
        
        .order-row:hover {
            background-color: rgba(59, 130, 246, 0.05);
        }
        
        .shimmer-effect {
            background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
            background-size: 1000px 100%;
            animation: shimmer 2s infinite linear;
        }
        
        .bg-gradient-fancy {
            background-image: linear-gradient(135deg, #0061ff 0%, #60efff 100%);
        }
        
        .smooth-shadow {
            box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
        }
        
        .child-row-enter {
            max-height: 0;
            opacity: 0;
            transition: all 0.3s ease-out;
            overflow: hidden;
        }
        
        .child-row-enter-active {
            max-height: 50px;
            opacity: 1;
        }
        
        .child-row-exit {
            max-height: 50px;
            opacity: 1;
        }
        
        .child-row-exit-active {
            max-height: 0;
            opacity: 0;
            transition: all 0.3s ease-in;
            overflow: hidden;
        }
        
        .hover-lift {
            transition: transform 0.2s ease;
        }
        
        .hover-lift:hover {
            transform: translateY(-2px);
        }
    `;

    if (isLoading) {
        return (
            <div className="max-w-6xl mx-auto px-4 py-8">
                <style>{animationStyles}</style>
                <div className="mb-8">
                    <div className="h-8 w-64 bg-gray-200 rounded shimmer-effect mb-8"></div>
                </div>
                <div className="bg-white rounded-2xl p-8 shadow-md">
                    <div className="h-12 w-full bg-gray-200 rounded shimmer-effect mb-6"></div>
                    <div className="space-y-4">
                        {[...Array(5)].map((_, i) => (
                            <div key={i} className="h-16 bg-gray-200 rounded shimmer-effect"></div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="max-w-6xl mx-auto px-4 py-8">
                <style>{animationStyles}</style>
                <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-md">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-red-700">{error}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-6xl mx-auto px-4 py-8">
            <style>{animationStyles}</style>

            {/* Header */}
            <div className="bg-gradient-fancy text-white rounded-2xl smooth-shadow p-6 mb-8 fade-in">
                <h1 className="text-3xl font-bold flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Mijn Afschrijvingen
                </h1>
                <p className="mt-2 text-blue-100">
                    Bekijk je volledige afschrijvingsgeschiedenis
                </p>
            </div>

            {/* History Card */}
            <div className="bg-white rounded-2xl shadow-md overflow-hidden mb-8 fade-in">
                <div className="px-6 py-4 bg-gradient-to-r from-blue-50 to-blue-100 border-b border-blue-200">
                    <div className="flex flex-wrap items-center justify-between">
                        <h2 className="text-xl font-semibold text-blue-800 flex items-center mb-2 sm:mb-0">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                            </svg>
                            Transactiegeschiedenis
                        </h2>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    {filteredOrders.length > 0 ? (
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    <th scope="col" className="px-6 py-3">Datum</th>
                                    <th scope="col" className="px-6 py-3">Beschrijving</th>
                                    <th scope="col" className="px-6 py-3">Door</th>
                                    <th scope="col" className="px-6 py-3 text-right">Bedrag</th>
                                    <th scope="col" className="px-6 py-3 w-10"></th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {filteredOrders.map((order, i) => {
                                    const isExpanded = expandedOrders[order.id];
                                    const orderTotal = calculateOrderTotal(order.order_lines);
                                    const hasMultipleItems = order.order_lines.length > 1;
                                    const mainItem = order.order_lines[0];

                                    return (
                                        <Fragment key={i}>
                                            <tr
                                                className={`order-row ${hasMultipleItems ? 'cursor-pointer hover:bg-blue-50' : ''}`}
                                                onClick={hasMultipleItems ? () => toggleOrderExpand(order.id) : undefined}
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {formatDate(order.created_at)}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                                    <div className="flex items-center">
                                                        {mainItem.quantity} × {mainItem.name}
                                                        {hasMultipleItems && (
                                                            <span className="ml-2 bg-blue-100 text-blue-800 text-xs font-semibold px-2 py-0.5 rounded-full">
                                                                +{order.order_lines.length - 1} meer
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {order.done_by_user}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-right font-medium text-gray-900">
                                                    {formatEuros(orderTotal)}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    {hasMultipleItems && (
                                                        <button
                                                            type="button"
                                                            className="text-blue-600 hover:text-blue-900"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                toggleOrderExpand(order.id);
                                                            }}
                                                        >
                                                            {isExpanded ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                                                </svg>
                                                            )}
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>

                                            {/* Child rows (order details) */}
                                            {isExpanded && hasMultipleItems && order.order_lines.slice(1).map((line, li) => (
                                                <tr key={`${i}-${li}`} className="bg-blue-50/50">
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-400"></td>
                                                    <td className="px-6 py-2 text-sm text-gray-700">
                                                        <div className="pl-4 border-l-2 border-blue-200">{line.quantity} × {line.name}</div>
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-400"></td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-right font-medium text-gray-700">
                                                        {formatEuros(line.price * line.quantity)}
                                                    </td>
                                                    <td className="px-6 py-2"></td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center py-12">
                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <h3 className="mt-2 text-base font-medium text-gray-900">Geen afschrijvingen gevonden</h3>
                            <p className="mt-1 text-sm text-gray-500">
                                {filterText ? 'Probeer een andere zoekopdracht' : 'Je hebt nog geen afschrijvingen gedaan'}
                            </p>
                            {filterText && (
                                <button
                                    onClick={() => setFilterText('')}
                                    className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Wis zoekopdracht
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/* Summary Card */}
            {orderHistory.length > 0 && (
                <div className="bg-white rounded-2xl shadow-md overflow-hidden fade-in">
                    <div className="px-6 py-4 bg-gradient-to-r from-blue-50 to-blue-100 border-b border-blue-200">
                        <h2 className="text-xl font-semibold text-blue-800 flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                            </svg>
                            Samenvatting
                        </h2>
                    </div>
                    <div className="p-6">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <div className="text-sm text-gray-500 mb-1">Totaal aantal afschrijvingen</div>
                                <div className="text-2xl font-bold text-gray-900">{orderHistory.length}</div>
                            </div>
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <div className="text-sm text-gray-500 mb-1">Totaal afgeschreven</div>
                                <div className="text-2xl font-bold text-red-600">
                                    {formatEuros(orderHistory.reduce((sum, order) =>
                                        sum + order.order_lines.reduce((lineSum, line) =>
                                            lineSum + line.price * line.quantity, 0), 0))}
                                </div>
                            </div>
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <div className="text-sm text-gray-500 mb-1">Meest recente afschrijving</div>
                                <div className="text-lg font-bold text-gray-900">
                                    {orderHistory.length > 0 ? formatDate(orderHistory[0].created_at) : '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HistoryList;