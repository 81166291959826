// Confetti.js - Add this to your project
import { useEffect, useState, useCallback } from "react";

// Confetti component that will render the animation
const Confetti = ({ active, duration = 3000, onComplete }) => {
  useEffect(() => {
    if (active) {
      const timer = setTimeout(() => {
        if (onComplete) onComplete();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [active, duration, onComplete]);

  if (!active) return null;

  return (
    <div className="fixed inset-0 z-50 pointer-events-none">
      <div className="absolute inset-0 confetti-container">
        {Array.from({ length: 150 }).map((_, i) => {
          const size = Math.random() * 10 + 5;
          const left = Math.random() * 100;
          const animDuration = Math.random() * 3 + 2;
          const delay = Math.random() * 0.5;
          const initialRotation = Math.random() * 360;

          // Random colors
          const colors = ['#FF5252', '#4CAF50', '#2196F3', '#FFEB3B', '#E040FB'];
          const color = colors[Math.floor(Math.random() * colors.length)];

          return (
            <div
              key={i}
              className="absolute confetti-piece"
              style={{
                width: `${size}px`,
                height: `${size}px`,
                backgroundColor: color,
                left: `${left}vw`,
                top: '-20px',
                transform: `rotate(${initialRotation}deg)`,
                animation: `fall ${animDuration}s ease-in forwards, sway ${animDuration / 2}s ease-in-out infinite alternate`,
                animationDelay: `${delay}s`
              }}
            />
          );
        })}
      </div>

      <style>{`
        @keyframes fall {
          0% { 
            top: -20px; 
            opacity: 1;
          }
          80% {
            opacity: 1;
          }
          100% { 
            top: 100vh;
            opacity: 0;
          }
        }
        
        @keyframes sway {
          0% {
            margin-left: 0;
            transform: rotate(${Math.random() * 360}deg);
          }
          100% {
            margin-left: ${Math.random() * 50 - 25}px;
            transform: rotate(${Math.random() * 360}deg);
          }
        }
        
        .confetti-piece {
          position: absolute;
          border-radius: 0;
          transform-origin: center;
        }
        
        .confetti-piece:nth-child(odd) {
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        }
        
        .confetti-piece:nth-child(even) {
          border-radius: 50%;
        }
        
        .confetti-piece:nth-child(4n) {
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        }
      `}</style>
    </div>
  );
};

// Hook to use the easter egg
export const useConfettiEasterEgg = () => {
  const [showConfetti, setShowConfetti] = useState(false);

  const triggerConfetti = useCallback(() => {
    // 1 in 20 chance (5%)
    if (Math.random() < 0.05) {
      setShowConfetti(true);
    }
  }, []);

  const hideConfetti = useCallback(() => {
    setShowConfetti(false);
  }, []);

  return {
    showConfetti,
    triggerConfetti,
    hideConfetti,
    ConfettiComponent: (
      <Confetti
        active={showConfetti}
        duration={4000}
        onComplete={hideConfetti}
      />
    )
  };
};

export default Confetti;