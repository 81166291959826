import { useEffect, useState } from "react"
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { login, getCurrentUser } from "../services/apiClient";
import Alert from "./Alert";
import Spinner from "./Spinner";

const LoginForm = () => {
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [attemptCount, setAttemptCount] = useState(0)
    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    const handleLogin = async (e) => {
        setIsLoading(true)
        setIsError(false)
        e.preventDefault()

        try {
            const res = await login(email, password)
            if (res.status === 200) {
                // Add a small delay to show the loading animation
                setTimeout(() => {
                    navigate('/ordering')
                }, 500);
            } else {
                setAttemptCount(prev => prev + 1)
                setIsLoading(false)
                setIsError(true)
            }
        } catch (error) {
            console.error("Login error:", error);
            setAttemptCount(prev => prev + 1)
            setIsLoading(false)
            setIsError(true)
        }
    }

    useEffect(() => {
        const checkUser = async () => {
            try {
                const result = await getCurrentUser()
                if (result.status === 200) {
                    navigate('/ordering')
                }
            } catch (error) {
                console.error("Failed to check user status:", error);
            }
        }

        checkUser();
    }, [navigate]);

    // Animation styles
    const animationStyles = `
        @keyframes fadeIn {
            from { opacity: 0; transform: translateY(-10px); }
            to { opacity: 1; transform: translateY(0); }
        }
        
        @keyframes shake {
            0%, 100% { transform: translateX(0); }
            10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
            20%, 40%, 60%, 80% { transform: translateX(5px); }
        }
        
        @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
        }
        
        @keyframes gradient {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
        }
        
        .fadeIn {
            animation: fadeIn 0.6s ease-out forwards;
        }
        
        .shake {
            animation: shake 0.5s ease-in-out;
        }
        
        .input-focus-effect {
            transition: all 0.3s ease-in-out;
        }
        
        .input-focus-effect:focus {
            transform: translateY(-2px);
            box-shadow: 0 10px 25px -5px rgba(59, 130, 246, 0.1), 0 10px 10px -5px rgba(59, 130, 246, 0.04);
        }
        
        .login-button-hover {
            transition: all 0.3s ease;
        }
        
        .login-button-hover:hover:not(:disabled) {
            transform: translateY(-2px);
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }
        
        .login-container {
            animation: fadeIn 0.8s ease-out;
        }
        
        .animated-gradient {
            background: linear-gradient(-45deg, #0061ff, #60efff, #1e90ff, #00bfff);
            background-size: 400% 400%;
            animation: gradient 15s ease infinite;
        }
    `;

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <style>{animationStyles}</style>

            <div className="max-w-md w-full space-y-8 login-container">
                {/* Logo and Header */}
                <div className="text-center">
                    <img
                        className="mx-auto h-16 w-auto"
                        src={require('../images/stamboek_sa.png')}
                        alt="Scouting Alblasserdam Stamboek"
                    />
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                        Stamboek
                    </h2>
                    <p className="mt-2 text-sm text-gray-600">
                        Log in op je account
                    </p>
                </div>

                {/* Login Card */}
                <div className="bg-white py-8 px-6 shadow rounded-2xl sm:px-10 border border-gray-200">
                    <form className="space-y-6" onSubmit={handleLogin}>
                        {/* Email Input Group */}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                </div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={`input-focus-effect pl-10 block w-full rounded-lg border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 ${isError ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : ''}`}
                                    placeholder="jouw@email.nl"
                                />
                            </div>
                        </div>

                        {/* Password Input Group */}
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Wachtwoord
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                    </svg>
                                </div>
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="current-password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={`input-focus-effect pl-10 pr-10 block w-full rounded-lg border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 ${isError ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : ''}`}
                                    placeholder="••••••••"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                    <button
                                        type="button"
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="text-gray-400 hover:text-gray-500 focus:outline-none"
                                    >
                                        {showPassword ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Remember me / Forgot password */}
                        <div className="flex items-center justify-between">
                            <div className="text-sm">
                                <NavLink to="/login/resetpassword" className="font-medium text-blue-600 hover:text-blue-500 transition-colors duration-200">
                                    Wachtwoord vergeten?
                                </NavLink>
                            </div>
                        </div>

                        {/* Alerts */}
                        {location.state?.passwordIsReset && !isError && (
                            <div className="mt-4 fadeIn">
                                <Alert
                                    title='Het wijzigen van je wachtwoord is gelukt'
                                    message='Log hieronder in met je nieuwe wachtwoord'
                                    color="green"
                                />
                            </div>
                        )}

                        {isError && (
                            <div className={`mt-4 ${attemptCount > 0 ? 'shake' : 'fadeIn'}`}>
                                <Alert
                                    title='Sorry, het inloggen is niet gelukt'
                                    message='Controleer je gebruikersnaam en wachtwoord en probeer het opnieuw'
                                />
                            </div>
                        )}

                        {/* Login Button */}
                        <div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`login-button-hover group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isLoading ? 'opacity-90' : ''}`}
                            >
                                {isLoading ? (
                                    <div className="flex items-center">
                                        <Spinner />
                                        <span className="ml-2">Bezig met inloggen...</span>
                                    </div>
                                ) : (
                                    <>
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-300 group-hover:text-blue-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                                            </svg>
                                        </span>
                                        Inloggen
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </div>

                {/* Bottom Decoration */}
                <div className="h-2 w-full rounded-full animated-gradient"></div>
            </div>
        </div>
    )
}

export default LoginForm